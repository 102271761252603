<template>
  <div>
<!--    <app-cells v-if="userRole !== 'hostess'" position="start">-->
<!--      <app-button @click="onAddButtonHandler" type="button" size="link">-->
<!--        <img src="@/assets/img/add-icon.svg" alt="">-->
<!--        Добавить расписание-->
<!--      </app-button>-->
<!--    </app-cells>-->
    <app-cells position="between">
      <app-cells position="start" :indent="false">
        <div class="date-picker-wrapper">
          <VueCtkDateTimePicker
            ref="datePicker"
            id="date_time_picker"
            class="custom-date-time-picker custom-date-time-picker--range"
            v-model="date_time_picker"
            :custom-shortcuts="custom_shortcuts"
            shortcut="thisWeek"
            @is-hidden="onIsHiddenPicker"
            format="DD.MM.YYYY"
            formatted="ll"
            label="Выберите даты"
            color="#2f73ff"
            button-color="#2f73ff"
            overlay
            no-header
            no-label
            range
          />
          <div v-if="date_time_picker_error" class="date-picker-wrapper__error">Выберите дату окончания</div>
        </div>
        <app-button @click="onAddButtonHandler" type="button" size="link">
          <img src="@/assets/img/add-icon.svg" alt="">
          Добавить расписание
        </app-button>
      </app-cells>
      <app-input
        v-model="input_search"
        @input.native="onFilterSearch($event.target.value)"
        class="input--450"
        type="search"
        placeholder="Поиск по номеру стола"
      />
    </app-cells>
    <app-table-head
      @update-limiter="onUpdateLimiter"
      :count="table.items.length"
      :page="pagination.page"
      :all="pagination.count"
      :limit="60"
    />
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    >
      <div slot="time_start" slot-scope="props">
        {{ props.row.time_start }} - {{ props.row.time_end }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__item pagination__item--prev'"
      :next-class="'pagination__item pagination__item--next'"
      :next-link-class="'pagination__link pagination__link--next'"
      :prev-link-class="'pagination__link pagination__link--prev'"
    />

    <app-sidebar-right
      :title="!isAdd ? 'Редактирование расписания' : 'Добавление расписания'"
      :class="{'sidebar-manage--full': show_sidebar}" @close-sidebar="show_sidebar = !show_sidebar"
    >
      <form @submit.prevent="onCheckForm">
        <app-form-group label="Стол" required>
          <v-select
            v-model="sidebarData.table"
            :reduce="item => item.id"
            :options="tableList"
            :filterable="true"
            :clearable="false"
            :searchable="true"
            label="number"
            placeholder="Выберите стол"
            class="select"
            :class="{ 'select--error': $v.sidebarData.table.$error }"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.number }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.number }}</div>
            </template>
          </v-select>
          <template #error>
            <div v-if="$v.sidebarData.table.$dirty && !$v.sidebarData.table.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Дата" label-for="date" required>
          <app-input
            v-model="sidebarData.date"
            v-mask="'99.99.9999'"
            placeholder="Введите дату"
            id="date"
            :error="$v.sidebarData.date.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.date.$dirty && !$v.sidebarData.date.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Время начала" label-for="time_start" required>
          <app-input
            v-model="sidebarData.time_start"
            v-mask="'99:99'"
            placeholder="Период с"
            id="time_start"
            :error="$v.sidebarData.time_start.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.time_start.$dirty && !$v.sidebarData.time_start.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Время окончания" label-for="time_end" required>
          <app-input
            v-model="sidebarData.time_end"
            v-mask="'99:99'"
            placeholder="Период по"
            id="time_end"
            :error="$v.sidebarData.time_end.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.time_end.$dirty && !$v.sidebarData.time_end.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-cells>
          <app-button v-if="!isAdd" ref="submit" :disabled="$v.sidebarData.$error">Сохранить</app-button>
          <app-button v-else ref="submit" :disabled="$v.sidebarData.$error">Добавить</app-button>
        </app-cells>
      </form>
      <app-button @click="onDelete" v-if="!isAdd" theme="error" ref="delete">Удалить</app-button>
    </app-sidebar-right>
  </div>
</template>

<script>
import { getOnlineBookings, getTables, deleteOnlineBooking, putOnlineBooking, postOnlineBooking } from '@/http'
import { dateFormatting } from '@/helpers'
import { debounce, isObject } from 'lodash'
import { required } from 'vuelidate/lib/validators'
import { parse, lightFormat, format, startOfWeek, endOfWeek } from 'date-fns'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import ru from 'date-fns/locale/ru'

export default {
  name: 'Deposit',
  components: { VueCtkDateTimePicker },
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'table.number', 'date', 'time_start'],
        options: {
          headings: {
            'id': 'ID',
            'table.number': 'Стол',
            'date': 'Дата',
            'time_start': 'Время',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 60,
          texts: {
            noResults: 'Нет подходящих записей',
          },
          rowClassCallback: () => 'table-default__row',
          resizableColumns: false
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 1,
      },
      table_limit: 60,
      sidebarData: {},
      date_start_deposits: '',
      date_end_deposits: '',
      isAdd: false,
      show_sidebar: false,
      tableList: [],
      input_search: '',
      date_time_picker: {},
      date_time_picker_error: false,
      custom_shortcuts: [
        { key: 'thisWeek', label: 'Эта неделя', value: 'isoWeek' },
        { key: 'lastWeek', label: 'Последняя неделя', value: '-isoWeek' },
        { key: 'last7Days', label: 'Последние 7 дней', value: 7 },
        { key: 'last30Days', label: 'Последние 30 дней', value: 30 },
        { key: 'thisMonth', label: 'Этот месяц', value: 'month' },
        { key: 'lastMonth', label: 'Последний месяц', value: '-month' },
        { key: 'thisYear', label: 'Этот год', value: 'year' },
        { key: 'lastYear', label: 'Последний год', value: '-year' }
      ]
    }
  },
  validations: {
    sidebarData: {
      table: { required },
      date: { required },
      time_start: { required },
      time_end: { required },
    }
  },
  created() {
    this.fetchItems(1, this.table_limit, this.input_search, format(startOfWeek(new Date(), {locale: ru}), 'yyyy-MM-dd'),
      format(endOfWeek(new Date(), {locale: ru}), 'yyyy-MM-dd'))
    getTables({search: '', active: '', hall: ''}).then(res => { this.tableList = res.data })
  },
  watch: {
    date_time_picker(value) {
      if (!value) {
        this.fetchItems(1, this.table_limit, this.input_search, '', '')
        this.date_time_picker_error = false
      }
    }
  },
  methods: {
    onCheckForm() {
      this.$v.sidebarData.$touch()
      if (this.$v.sidebarData.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (!this.isAdd) {
        putOnlineBooking(this.sidebarData.id, this.normalizeForm())
          .then(() => {
            this.$refs.submit.preload = false
            this.show_sidebar = false
            this.fetchItems()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Расписание обновлено'
            })
          })
          .catch(() => { this.$refs.submit.preload = false })
      } else {
        postOnlineBooking(this.normalizeForm())
          .then(() => {
            this.$refs.submit.preload = false
            this.show_sidebar = false
            this.fetchItems()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Расписание добавлено'
            })
          })
          .catch(() => { this.$refs.submit.preload = false })
      }
    },
    normalizeForm() {
      const normalizedForm = { ...this.sidebarData }

      if (isObject(normalizedForm.table)) normalizedForm.table = normalizedForm.table.id
      normalizedForm.date = lightFormat(parse(normalizedForm.date, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd')
      return normalizedForm
    },
    fetchItems(
      page = this.pagination.page,
      page_size = this.table_limit,
      search = this.input_search,
      time_start = this.date_time_picker ? dateFormatting(this.date_time_picker.start, 'normal-to-iso-small') : '',
      time_end = this.date_time_picker ? dateFormatting(this.date_time_picker.end, 'normal-to-iso-small') : '') {
      getOnlineBookings({ page, page_size, search, time_start, time_end })
        .then(res => {
          this.table.items = res.data.results.map(booking => ({
            ...booking,
            time_start: booking.time_start.slice(0, 5),
            time_end: booking.time_end.slice(0, 5),
            date: lightFormat(parse(booking.date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy')
          }))
          this.pagination.pages = res.data.pages
          this.pagination.count = res.data.count
        })
    },
    onIsHiddenPicker() {
      if (this.date_time_picker &&
        this.date_time_picker.hasOwnProperty('start') &&
        this.date_time_picker.start &&
        this.date_time_picker.hasOwnProperty('end') &&
        this.date_time_picker.end) {
        this.date_time_picker_error = false
        this.pagination.page = 1
        this.fetchItems(
          1, this.table_limit, this.input_search,
          dateFormatting(this.date_time_picker.start, 'normal-to-iso-small'),
          dateFormatting(this.date_time_picker.end, 'normal-to-iso-small')
        )
      } else if (this.date_time_picker &&
        this.date_time_picker.start &&
        !this.date_time_picker.end) {
        this.date_time_picker_error = true
      }
    },
    onRowClick(element) {
      this.$v.sidebarData.$reset()
      this.isAdd = false
      this.show_sidebar = true
      this.sidebarData = { ...element.row }
    },
    onClickPagination(page) {
      this.fetchItems(page, this.table_limit)
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems(this.pagination.page, this.table_limit)
    },
    onFilterSearch(search) {
      this.pagination.page = 1
      this.searchFilter(search, this)
    },
    searchFilter: debounce((search, vm) => {
      vm.fetchItems(1, vm.table_limit, search, vm.date_time_picker ? vm.date_time_picker.start : '', vm.date_time_picker ? vm.date_time_picker.end : '')
    }, 350),
    onAddButtonHandler() {
      this.$v.sidebarData.$reset()
      this.sidebarData = {}
      this.isAdd = true
      this.show_sidebar = true
    },
    onDelete() {
      deleteOnlineBooking(this.sidebarData.id)
        .then(() => {
          this.fetchItems()
          this.show_sidebar = false
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Расписание удалено'
          })
        })
        .finally(() => {
          this.$refs.delete.preload = false
        })
    }
  }
}
</script>
